<script lang="ts" setup>
import { useField } from 'vee-validate'
import { AsfCheckboxProps } from '@ui/types'

const props = withDefaults(defineProps<AsfCheckboxProps>(), {
  focusable: true,
  optionCount: 0
})

const rules = computed(() => {
  if (typeof props.checked === 'boolean' && props.required) {
    return {
      required: { allowFalse: false },
      ...props.validationRules
    }
  }

  return {
    ...props.validationRules
  }
})
const fieldId = computed(() => props.id || props.name || `asf-checkbox-${props.id || props.name}`)
const {
  errorMessage,
  value: reactiveValue,
  resetField
  // setValue
} = useField(() => fieldId.value, rules, {
  initialValue: props.checked
})

const emit = defineEmits(['update:checked'])
const fieldKey = computed(() => props.name)

watch(reactiveValue, (newValue) => {
  emit('update:checked', newValue)
})

defineExpose({
  fieldKey,
  resetField
})
</script>

<template>
  <div
    class="asf-checkbox"
    :class="{ 'is-checked': reactiveValue, 'is-disabled': disabled, 'is-invalid': !!errorMessage }"
  >
    <label :class="['asf-checkbox__label', { 'is-disabled': disabled, 'is-required': required }]">
      <input
        :id="id"
        v-model="reactiveValue"
        v-e2e="'ta-checkbox'"
        type="checkbox"
        :disabled="disabled"
        :required="required"
        :aria-label="label"
        :aria-checked="!!reactiveValue"
        :aria-disabled="disabled"
        :aria-required="required"
        :aria-errormessage="errorMessage"
        :form="form"
        :name="name"
        class="asf-checkbox__input"
        v-bind="$attrs"
        :tabindex="focusable ? '0' : '-1'"
      />
      <slot name="content-checkmark" v-bind="{ isChecked: !!reactiveValue, disabled }">
        <div v-e2e="'privacy-checkbox'" :class="['asf-checkbox__checkmark', { 'is-invalid': !!errorMessage }]">
          <AsfIcon name="check" size="4" v-show="reactiveValue" class="asf-checkbox__icon" />
        </div>
      </slot>
      <slot name="content-label">
        <span class="asf-checkbox__label-content" :aria-hidden="labelAriaHidden">{{ label }}</span>
        <span v-if="optionCount" class="asf-variation-multi-select__option_count">({{ optionCount }})</span>
      </slot>
    </label>
    <div v-if="errorMessage" class="asf-checkbox__error" role="alert">
      <transition name="asf-fade">
        <span>{{ errorMessage }}</span>
      </transition>
    </div>
    <div v-if="caption" class="asf-checkbox__caption">
      <slot name="content-caption-message" v-bind="{ caption }">
        <span>{{ caption }}</span>
      </slot>
    </div>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Checkbox/Checkbox.css';
</style>
